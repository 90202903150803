import { userConstants } from '_constants'
import { userService } from '_services'
import { alertActions } from '.'

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  forgetPassword,
  getMe,
  updateMe,
  cancelTwoStep,
  verifyTwostep,
  onboardLogin,
}

function login(username, password, from) {
  return (dispatch) => {
    dispatch(request({ username }))

    userService.login(username, password)
      .then(
        (user) => {
          if (user.jwt) {
            dispatch(success(user))
            window.location.href = '/'
          } else if (user.accessToken === 'two-step-required') {
            dispatch(requireTwoStep(user))
          }
        },
        (error) => {
          console.log('error', error)
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        },
      )
  }

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function requireTwoStep(data) { return { type: userConstants.LOGIN_REQUEST_TWO_STEP, data } }
}

function onboardLogin(token, password, from) {
  return (dispatch) => {
    dispatch(request({ username: token }))

    userService.onboardLogin(token, password)
      .then(
        (user) => {
          if (user.jwt) {
            dispatch(success(user))
            window.location.href = from
          }
        },
        (error) => {
          console.log('error', error)
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        },
      )
  }

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function verifyTwostep(code, resendToken) {
  return (dispatch) => {
    userService.verifyTwoStepCode(code, resendToken)
      .then(
        (data) => {
          if (data.verification === 'success') {
            dispatch(success(data.user))
            window.location.href = '/'
          } else {
            // dispatch(failure("Verification code is wrong"));
            dispatch(alertActions.error('Verification code is wrong'))
          }
        },
        (error) => {
          console.log('error', error)
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        },
      )
  }

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function requireTwoStep(data) { return { type: userConstants.LOGIN_REQUEST_TWO_STEP, data } }
}

function cancelTwoStep() {
  return (dispatch) => {
    dispatch(cancelTwoStep())
  }

  function cancelTwoStep() { return { type: userConstants.LOGIN_FAILURE } }
}

function forgetPassword(email) {
  return (dispatch) => {
    userService.forgetPassword(email)
      .then(
        (ret) => {
          dispatch(alertActions.success('We sent a reset password link to your email. Please check it and click that link in your email.'))
        },
        (error) => {
          console.log('error', error)
          // dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message))
        },
      )
  }
}

function logout() {
  userService.logout()
  return { type: userConstants.LOGOUT }
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user))

    userService.register(user)
      .then(
        (user) => {
          dispatch(success())
          window.location.href = '/login'
          dispatch(alertActions.success('Your registration request sent to 1KIN team. They will review your request asap.'))
        },
        (error) => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        },
      )
  }

  function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    userService.getAll()
      .then(
        (users) => dispatch(success(users)),
        (error) => dispatch(failure(error.toString())),
      )
  }

  function request() { return { type: userConstants.GETALL_REQUEST } }
  function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
  function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getMe() {
  return (dispatch) => {
    dispatch(request())

    userService.getMe()
      .then(
        (user) => dispatch(success(user)),
        (error) => dispatch(failure(error.toString())),
      )
  }

  function request() { return { type: userConstants.GETME_REQUEST } }
  function success(user) { return { type: userConstants.GETME_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GETME_FAILURE, error } }
}

function updateMe(user, cb) {
  return (dispatch) => {
    dispatch(request())

    userService.update(user)
      .then(
        (user) => {
          dispatch(success(user))
          if (cb) {
            cb(true)
          }
        },
        (error) => {
          dispatch(failure(error.toString()))
          if (cb) {
            cb(false)
          }
        },
      )
  }

  function request() { return { type: userConstants.GETME_REQUEST } }
  function success(user) { return { type: userConstants.GETME_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GETME_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id))

    userService.delete(id)
      .then(
        (user) => dispatch(success(id)),
        (error) => dispatch(failure(id, error.toString())),
      )
  }

  function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}
