import { userConstants } from '_constants'

export function authentication(inState, action) {
  const user = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user') || '{}') : null
  const initialState = user ? { loggedIn: true, user } : { requestTwoStep: false, twoStep: undefined }
  const state = inState || initialState

  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        requestTwoStep: false,
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        requestTwoStep: false,
      }
    case userConstants.LOGIN_REQUEST_TWO_STEP:
      return {
        requestTwoStep: true,
        twoStepData: action.data,
      }
    case userConstants.LOGIN_FAILURE:
      return {
        requestTwoStep: false,
      }
    case userConstants.LOGOUT:
      return {
        requestTwoStep: false,
      }
    case userConstants.GETME_SUCCESS:
      return {
        user: {
          ...state.user,
          client: action.user,
        },
      }
    default:
      return state
  }
}
