import axios from 'axios'
import { authHeader } from '_helpers'
import { handleResponse } from './middleware'

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  upload,
  collectibleImageUpload,
  logoImageUpload,
  forgetPassword,
  checkValidResetPasswordToken,
  checkOnBoardToken,
  resetPassword,
  getMe,
  generateApiKey,
  fetchApiKey,
  changePassword,
  checkPassword,
  verifyPhoneNumber,
  verifyPhoneNumberCode,
  setTwostepNumber,
  setTwostepStatus,
  deleteTwostepNumber,
  verifyTwoStepCode,
  resendTwostepCode,
  onboardLogin,
  recapchaValidate,
}

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ identifier: username, password }),
  }

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.jwt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

function forgetPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/requestResetPassword`,
    requestOptions,
  ).then(handleResponse)
}

function checkValidResetPasswordToken(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/checkValidResetPasswordToken`,
    requestOptions,
  ).then(handleResponse)
}

function recapchaValidate(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/utils/recaptchaVerify`,
    requestOptions,
  ).then(handleResponse)
}

function checkOnBoardToken(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/checkOnBoardToken`,
    requestOptions,
  ).then(handleResponse)
}

function onboardLogin(token, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, password }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/onboardLogin`,
    requestOptions,
  )
    .then(handleResponse)
    .then((user) => {
      if (user.jwt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

function resetPassword(token, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, password }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/resetPassword`,
    requestOptions,
  ).then(handleResponse)
}

function verifyTwoStepCode(code, resendToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ code, resendToken }),
  }
  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/verifyTwostep`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      if (data.verification === 'success') {
        const { user } = data
        if (user.jwt) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user))
        }
      }

      return data
    })
}

function resendTwostepCode(resendToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ resendToken }),
  }
  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/resendTwostepCode`,
    requestOptions,
  ).then(handleResponse)
}

async function upload(file, uploadConfig) {
  // console.log('file', file)
  const formData = new FormData()
  formData.append('files', file)

  return axios
    .post(`${process.env.NEXT_PUBLIC_API_URL}/upload`, formData, {
      ...uploadConfig,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((result) => result.data)
}

async function collectibleImageUpload(file, uploadConfig) {
  // console.log('file', file)
  const formData = new FormData()
  formData.append('files', file)

  return axios
    .post(
      `${process.env.NEXT_PUBLIC_API_URL}/utils/collectibleImageUpload`,
      formData,
      {
        ...uploadConfig,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      },
    )
    .then((result) => result.data)
}

async function logoImageUpload(file, uploadConfig) {
  // console.log('file', file)
  const formData = new FormData()
  formData.append('files', file)

  return axios
    .post(`${process.env.NEXT_PUBLIC_API_URL}/utils/logoImageUpload`, formData, {
      ...uploadConfig,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((result) => result.data)
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/users`, requestOptions).then(
    handleResponse,
  )
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/users/${id}`,
    requestOptions,
  ).then(handleResponse)
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/register`,
    requestOptions,
  ).then(handleResponse)
}

function update(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/me`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      let userData = localStorage.getItem('user')
      if (userData) {
        userData = JSON.parse(userData)
        userData.client = user
        localStorage.setItem('user', JSON.stringify(userData))
      }

      return user
    })
}

function changePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/changePassword`,
    requestOptions,
  ).then(handleResponse)
}

function checkPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/checkCurrentPassword`,
    requestOptions,
  ).then(handleResponse)
}

function verifyPhoneNumber(phoneNumber) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ phoneNumber }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/verifyPhoneNumber`,
    requestOptions,
  ).then(handleResponse)
}

function verifyPhoneNumberCode(code) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ code }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/verifyPhoneNumberCode`,
    requestOptions,
  ).then(handleResponse)
}

function setTwostepNumber(mobileNumber) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ mobileNumber }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/setTwostepNumber`,
    requestOptions,
  ).then(handleResponse)
}

function setTwostepStatus(status) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ status }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/setTwostepStatus`,
    requestOptions,
  ).then(handleResponse)
}

function deleteTwostepNumber() {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/deleteTwostepNumber`,
    requestOptions,
  ).then(handleResponse)
}

function getMe() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/me`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      let userData = localStorage.getItem('user')
      if (userData) {
        userData = JSON.parse(userData)
        userData.client = user
        localStorage.setItem('user', JSON.stringify(userData))
      }

      return user
    })
}

function generateApiKey() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/generateApiKey`,
    requestOptions,
  ).then(handleResponse)
}

function fetchApiKey() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/fetchApiKey`,
    requestOptions,
  ).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/users/${id}`,
    requestOptions,
  ).then(handleResponse)
}
