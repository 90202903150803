import { authHeader } from '_helpers'
import { handleResponse } from './middleware'

export const collectibleService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  importCodes,
  getMyClaims,
  claimCompleted,
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/myCollectibles`,
    requestOptions,
  ).then(handleResponse)
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/myCollectibles/${id}`,
    requestOptions,
  ).then(handleResponse)
}

function register(collectible) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(collectible),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/createCollectible`,
    requestOptions,
  ).then(handleResponse)
}

function update(collectible) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(collectible),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/updateCollectible`,
    requestOptions,
  ).then(handleResponse)
}

function _delete(collectibleId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      collectible_id: collectibleId,
    }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/updateCollectible`,
    requestOptions,
  ).then(handleResponse)
}

function importCodes(importData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(importData),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/importCodes`,
    requestOptions,
  ).then(handleResponse)
}

function getMyClaims(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/getMyClaims`,
    requestOptions,
  ).then(handleResponse)
}

function claimCompleted(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/claimCompleted`,
    requestOptions,
  ).then(handleResponse)
}
