export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.jwt) {
    return {
      Authorization: `Bearer ${user.jwt}`,
      // 'onekin-api-key': user.client.api_key, //No need from one kin platform
    }
  }
  return {}
}
