import { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import './index.css'
import '_components/quillCustom.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css' // Bubble theme CSS
import 'react-quill/dist/quill.core.css' // Core theme CSS
import 'react-datepicker/dist/react-datepicker.css'

import { Toaster } from 'react-hot-toast'
import { alertActions } from '_actions'

import { Spinner } from '@1kinlabs/ui-core/atoms/Spinner'
import { ThemeProvider } from '@1kinlabs/ui-core/theme'

import ThemeComponent from '@core/theme/ThemeComponent'
import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext'

import { store, configureFakeBackend } from '_helpers'

function Content({ children }) {
  const router = useRouter()
  const alert = useSelector((state) => state.alert)
  const dispatch = useDispatch()

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = window.document.getElementById(id)

      if (!isScriptExist) {
        const script = window.document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        window.document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_SITE_KEY}`, () => {
      console.log('Script loaded!')
    })
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      // clear alert on location change
      dispatch(alertActions.clear())
    })
  }, [])

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        dispatch(alertActions.clear())
      }, 5000)
    }
  }, [alert.message])

  return (
    <div>
      {alert.message
        && (
          <div
            className={`alert ${alert.type}`}
            style={{
              position: 'absolute', left: 0, right: 0, top: 0, zIndex: 100,
            }}
          >
            {alert.message}
          </div>
        )}
      {children}
      <Toaster />
    </div>
  )
}

export default function App({ Component, pageProps }) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    // setup fake backend
    configureFakeBackend()
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Content>
          {isClient ? <Component {...pageProps} /> : <Spinner />}
        </Content>
      </ThemeProvider>
    </Provider>
  )
}

App.getInitialProps = async () => ({})
