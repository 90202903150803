export const handleResponse = (response) => response.text().then((text) => {
  const data = text && JSON.parse(text)
  if (!response.ok) {
    if (response.status === 401) {
      window.localStorage.clear()
      window.location.href = '/login'
    }

    console.log('error data', data, response)

    const error = (data && data.message) || (data && data.error) || response.statusText
    return Promise.reject(error)
  }

  return data
})
