import { authHeader } from '_helpers'
import { handleResponse } from './middleware'

export const gameService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/myGames`,
    requestOptions,
  ).then(handleResponse)
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/myGames/${id}`,
    requestOptions,
  ).then(handleResponse)
}

function register(game) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(game),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/createGame`,
    requestOptions,
  ).then(handleResponse)
}

function update(game) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(game),
  }
  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/updateGame`,
    requestOptions,
  ).then(handleResponse)
}

function _delete(gameId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      game_id: gameId,
    }),
  }

  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/clients/updateGame`,
    requestOptions,
  ).then(handleResponse)
}
